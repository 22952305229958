@use "../../assets/styles/abstracts/" as *;

.message-content {

  p,
  span,
  ul {
    background-color: white !important;
    color: black !important;
    margin: unset !important;
  }

  iframe {
    width: 100%;
    height: auto;
  }

  img {
    max-width: 100% !important;
    height: auto;
  }
}

.message-client {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 20px;
  background-color: $primary;
  font-size: 13px;
  line-height: 25px;
  box-shadow: 0 3px 4px 0 rgba(87, 116, 151, 0.1);
  max-width: 350px;
  color: $pure_white;
  align-self: flex-end;

  .message-content {
    overflow-wrap: anywhere;
    position: relative;
  }

  &:hover {
    .toolTip {
      visibility: visible;
    }
  }

  @include mediumScreen() {
    max-width: 300px;
  }

  @include smallScreen() {
    max-width: 260px;
  }

  @include extraSmallScreen() {
    max-width: 350px;
  }
}

.taki-chat-file {
  max-width: 254px !important;
  word-break: break-all !important;

  .taki-chat-file-img {
    cursor: pointer !important;
    width: 100% !important;
    height: auto !important;
    // object-fit: contain !important ;
  }

  iframe {
    width: 100% !important;
    height: auto !important;
  }
}

.taki-chat-image-content-operator-msg .taki-chat-file-img {
  cursor: pointer !important;
  width: 100% !important;
  height: 166px !important;
  object-fit: contain !important;
}

.taki-chat-file-img {
  cursor: pointer !important;
  width: 100% !important;
  height: 166px !important;
  object-fit: contain !important;
}

.message-operator {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 25px;
  background-color: $pure_white;
  box-shadow: 0 3px 4px 0 rgba(87, 116, 151, 0.1);
  max-width: 80%;
  align-self: flex-start;

  .message-content {
    overflow-wrap: anywhere;
  }

  &:hover {
    .toolTip {
      visibility: visible;
    }
  }
}

.bot-message {
  display: flex;
  flex-direction: column !important;
}

.not-sent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-end;
  flex-direction: column;
  color: $quinary;
  font-size: 13px;
  cursor: pointer;
}

.mgl40 {
  margin-left: 40px;
}

.choices-container {
  display: flex !important;
  gap: 10px !important;
  align-items: flex-start;
  flex-direction: column;
  line-height: 20px;

  .single-choice {
    display: flex;
  }
}

.message-operator-container {
  display: flex;
  gap: 7px;

  .message-operator-img {
    width: 32px;
    height: 32px;
    margin-top: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    svg {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .message-operator-wrapper {
    position: relative;

    .toolTip {
      margin-left: 0;
      top: -45%;
    }

    &:hover {
      .toolTip {
        visibility: visible;
      }
    }
  }
}

.clientmsg-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  gap: 5px;
}

.client-msg-seen {
  width: 25px;
  height: 25px;
  object-fit: contain;
  padding: 8px 0px 0px 2px;
}

.client-msg-sent {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.client-msg-sent-container,
.client-msg-seen-container {
  align-self: flex-end;
  position: relative;
  bottom: 5px;
}

.taki-chat-text-link {
  text-decoration: none;
  font-weight: bold;
  color: #2898ff;
  font-size: 14px;
  line-break: anywhere;
  text-decoration: underline;
}

.taki-chat-pdf-file {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  cursor: pointer;
  width: 89px;
  border: 1px solid #cdcdd6;
  border-radius: 10px;
}

.taki-chat-video-file {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #cdcdd6;
  border-radius: 10px;
}

.taki-chat-file-content {
  margin: 0;
}

.taki-chat-file-title {
  font-weight: bold;
  font-size: 17px;
  margin: 0 5px 0 0px !important;
}

.download-btn-video-txt {
  border: none;
  border-radius: 5px;
  font-size: 15px;
  padding: 6px;
  background: white;
  cursor: pointer;
}

.taki-chat-pdf {
  display: flex;
  flex-direction: column;

  & img {
    width: 32px;
    height: 32px;
  }

  & span {
    font-weight: 500;
    font-size: 18px;
  }

  & .download-file-icon {
    cursor: pointer;
  }
}

.taki-chat-video-wmv {
  & div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & img {
    width: 32px;
    height: 32px;
  }

  & span {
    font-weight: 500;
    font-size: 18px;
  }

  & .download-file-icon {
    cursor: pointer;
  }
}

.taki-chat-image-content-operator-msg,
.taki-chat-file-content-operator-msg,
.message-content-operator-msg {
  color: black !important;
  //line-break: anywhere;
  word-break: break-word;
  width: 100%;
}

.taki-chat-file-content-client-msg,
.message-content-client-msg {
  color: white !important;
  line-break: anywhere;
}

.taki-chat-file-content-container {
  color: black !important;
  line-break: anywhere;
}

.taki-chat-file-content-container audio {
  max-width: 250px !important;
  display: block !important;

  @include mediumScreen() {
    max-width: 250px !important;
  }

  @include smallScreen() {
    max-width: 200px !important;
  }

  @include extraSmallScreen() {
    max-width: 250px !important;
  }
}

.taki-chat-pdf-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.taki-chat-pdf-from-operator {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 500;
}

.download-file-icon {
  cursor: pointer;
}

.check::after {
  clear: both;
  display: block;
  content: "";
}

/* Styling Checkbox Starts */
.bot-msg-checkbox-label {
  color: white;
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 50px;
  width: 24px;
  clear: both;
}

.bot-msg-checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 31px;
}



.bot-msg-button {
  color: #fff;
  font-size: 14px;
  padding: 10px;
  margin: auto;
  border: 0;
  border-radius: 13px;
  background-color: #6932fa;
  font-weight: bold;
  cursor: pointer;
}

.bot-msg-input {
  padding: 10px;
  border: 1px dashed #6932fa;
  border-radius: 8px;
  background: white;
}

.bot-msg-select {
  padding: 10px;
  border: 1px dashed #6932fa;
  border-radius: 8px;
  background: white;
  width: 90%;
}

.ai-message {
  display: flex;
  flex-direction: column;
}

.ai-message .bot-msg-button {
  height: 50px !important;
  padding: 5px !important;
  cursor: pointer;
}

.bot-msg-checkbox-label {
  color: white;
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 50px;
  width: 24px;
  clear: both;
}

.bot-msg-checkbox-label input {
  display: none;
  /* Hide the checkbox */
}

.bot-msg-checkbox-label .bot-msg-mark {
  top: 13px;
  position: absolute;
  height: 24px;
  width: 24px;
  border-radius: 5px;
  transition: all 0.3s ease-in;
}

.single-choice {
  cursor: pointer;
  margin: 3px 0px;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 8px;
  width: 100%
}

.bot-msg-mark {
  color: black;
}

.single-choice:hover {
  background-color: var(--dynamic-color) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.single-choice.selected {
  background-color: var(--dynamic-color, rgba(80, 50, 250, 0.5));
  border-color: var(--dynamic-color, rgba(80, 50, 250, 0.5));
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}