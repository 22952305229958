@use "../../assets/styles/abstracts/" as *;
.upload-container {
  .upload-button {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background-color: $primary;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: $pure_white;
    font-size: 23px;
    font-weight: 100;
    line-height: initial !important;
    flex-direction: row !important;
    padding: 0 !important;
    .takiChatfileInput {
      display: none;
    }
  }
}
