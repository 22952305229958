@use "../abstracts" as *;

.chatbox {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;

  * {
    font-family: "Poppins", sans-serif !important  ;
    box-sizing: border-box;
    // direction: ltr !important;
  }
}

.ev-none {
  pointer-events: none;
}

#root {
  height: 100vh;
}
