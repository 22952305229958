@use "./assets/styles/base";

//Components

// clientChat scss

@use "../src/components/ClientChat";

// RatingModal

@use "../src/components/RatingModal";

// Employee scss

@use "../src/components/Employee";

// toolTip

@use "../src/components/ToolTip";

// Upload

@use "../src/components/Upload";

// ImagePopup

@use "../src/components/ImagePopup";

// Message

@use "../src/components/Messages";

// Audio Recorder

@use "./components/AudioRecorder";


.poste-history-element {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.history {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (max-width: 909px) {
  .header {
    width: 70% !important;
    margin:0 auto;
  }
  .poste-banner {
    width: 100% !important;
  }

  .poste-logo {
    width: 40% !important;
  }
}

@media screen and (max-width: 848px) {
  .poste-banner {
    width: 100% !important;
  }

  .poste-logo {
    width: 40% !important;
  }
}
@media screen and (max-width: 645px) {
  .poste-banner {
    min-width: 100% !important;
  }

  .poste-logo {
    min-width: 40% !important;
  }
}
@media screen and (max-width: 545px) {
  .poste-banner {
    width: 110% !important;
  }

  .poste-logo {
    width: 30% !important;
  }
}