@use "../../assets/styles/abstracts/" as *;
.toolTip {
  visibility: hidden;
  background-color: #0d0e0fe8;
  color: $pure_white;
  text-align: center;
  padding: 5px;
  border-radius: 3px;
  top: 100%;
  left: 50%;
  margin-left: -73px;
  position: absolute;
  z-index: 500;
  line-height: 18px !important;
  font-size: 10px;
  white-space: nowrap;
  font-weight: 600;
}
