.audio-record-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .inner-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .status {
    text-transform: capitalize;
  }

  .timer {
    font-weight: 500;
    letter-spacing: 0.2rem;
  }
  .blink-animation {
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  button {
    border: none;
    cursor: pointer;
    border-radius: 50%;
  }

  .btn-record {
    width: 2rem;
    height: 2rem;
    padding: 0;
    background-color: #fff;
  }
  .btn-stop {
    padding: 0;
    background-color: #fff;
    svg {
      font-size: 2rem;
    }
  }
  .btn-play {
    width: 2rem;
    height: 2rem;
    font-size: 1.2rem;
    background-image: linear-gradient(rgb(249, 199, 129), rgb(253, 226, 145));
  }
  .btn-reset {
    width: 3rem;
    height: 3rem;
    font-size: 1rem;
    background-color: #124e8a;
    color: #fff;
  }

  @keyframes blink {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
