.employees-container {
  display: flex;

  gap: 5px;

  .employee-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    .employee {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      overflow: hidden;
      margin: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #074cff99;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      // select under element
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .toolTip {
      margin-left: 0;
      top: 84%;
    }
    &:hover {
      .toolTip {
        visibility: visible;
      }
    }
  }

  animation: fadeInDown 1s;
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

