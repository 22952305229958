@use "../../assets/styles/abstracts/" as *;

.chatbox {

  // direction: ltr !important;
  // transition: all 0.5s ease 0s;
  .chat-circle {
    position: fixed;
    z-index: 123456789 !important;
    bottom: 20px;
    right: 15px;
    background: rgba(105, 50, 250, 255);
    width: 54px;
    height: 54px;
    border-radius: 54px;
    color: white;
    padding: 28px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.04);

    img {
      object-fit: cover;
      max-width: none !important;
      max-height: none !important;
      width: 65px;
      height: 65px;
      border-radius: 100%;
    }

    perspective: 1000px;
    transform-style: preserve-3d;

    // transition: all 0.5s ease 0s;
  }

  .client-chatbox {
    width: 400px;
    height: 85%;
    background: #fff;
    z-index: 1234567888;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 24px;
    bottom: 28px;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    animation: fadeIn 0.5s ease-in-out;
    transition: all 0.5s ease 0s;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @include mediumScreen() {
      width: 360px;
      // height: 560px;
    }

    @include smallScreen() {
      width: 320px;
      // height: 480px;
    }

    @include extraSmallScreen() {
      width: 100% !important;
      height: 80% !important;
      border-radius: 0;
      right: 0;
      bottom: 0;
    }

    .chat-box-header {
      // cursor: pointer;
      padding: 20px;
      // padding-top: 20px;
      background-color: $primary;
      position: relative;

      // direction: ltr !important;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("https://client.crisp.chat/static/images/tiles/charlie-brown.svg?8ce76c3") !important;
        background-size: 34px;
        opacity: 0.7;
        z-index: -1;
        animation: fadeInDown 1s;
      }

      .chat-box-header-title {
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        //  padding-right: 25px;
        font-size: 13.5px;
        //   direction: ltr !important;
        line-height: 20px;
        font-weight: bold;
        font-family: "Poppins", sans-serif !important;

        svg {
          width: 20px !important;
          height: 20px !important;
          cursor: pointer;
        }

        .css-i4bv87-MuiSvgIcon-root {
          width: 25px !important;
          height: 25px !important;
        }

        animation: fadeInDown 1s;
      }

      .chat-box-header-desc {
        display: inline-block;
        color: #ffffff;
        letter-spacing: 0.2px;
        white-space: nowrap;
        font-family: "Poppins", sans-serif !important;

        opacity: 0.8;
        font-size: 12.6px;
        line-height: 16px;
        animation: fadeInDown 1s;
      }
    }

    .chat-box-body {
      width: 100%;
      flex-grow: 1;
      background-color: rgb(247, 247, 247);
      overflow-y: scroll;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      padding: 5px 5px 0px 10px;
      position: relative;

      &::-webkit-scrollbar {
        width: 4px;
        background-color: initial !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: initial !important;
      }

      .fade-out {
        opacity: 0;
        transition: opacity 2s ease;
      }

      .is-typing {
        position: absolute;
        bottom: 20px;
      }

      .today-date {
        display: flex;
        flex-direction: row;
        padding: 10px 0px;

        .today-date-text {
          color: #191919;
          font-size: 11.7px;
          letter-spacing: 0;
          margin: 0 auto;
          padding: 0 11px;
          font-weight: bold;
        }

        &:before,
        &:after {
          content: "";
          background-color: #4a4a66;
          height: 1px;
          margin-top: 6px;
          opacity: 0.16;
          flex: 1;
        }

        &:after {
          margin-right: 21px;
          float: right;
          display: flex;
          flex-direction: row;
        }

        &:before {
          margin-left: 21px;
          float: left;
          display: flex;
          flex-direction: row;
        }
      }
    }

    .chat-box-footer {
      background: #fff;
      border-width: 1px 0 0;
      border-style: solid;
      box-sizing: border-box;
      height: 57px;
      border-color: rgba(163, 126, 0, 0.175);

      display: flex;

      .chat-box-footer-form {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-evenly;

        .chat-box-footer-textarea {
          width: 70%;
          height: 100%;
          border: none;
          outline: none;
          padding: 14px;
          resize: none;
          font-size: 15.6px;
          line-height: 18px;
          font-weight: 500;
          font-family: "Poppins", sans-serif;
          background-color: #fff !important;

          &::placeholder {
            color: $primary;
            font-size: 14px;
            font-weight: bold;
            animation: fadeInDown 1s;
          }
        }

        .chat-box-footer-icons {
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 2px;

          //  direction: ltr !important;
          img {
            width: 25px;
            height: 25px;
            cursor: pointer;
          }

          .chat-box-footer-icons-emoji {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 27px;
              height: 27px;
              cursor: pointer;
            }
          }

          .send-button {
            width: 31px;
            height: 31px;
            border-radius: 50%;
            background-color: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            img {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }

  .colored {
    &::placeholder {
      color: var(--dynamic-color) !important;
    }
  }
}

.reconnection-container {
  & .reconnection {
    font-size: 12px;
    text-align: center;
    background: #ed3863;
    color: #ffff;
    font-weight: bold;
    padding: 2px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.edited {
  font-size: 10px;
  color: $primary;
  font-weight: 600;
  margin: 0;
}

// emoji picker style
.epr-category-nav {
  display: none !important;
}

.opened {
  padding: 0px 0px 0px 0px !important;
}

.EmojiPickerReact {
  --epr-picker-width: 291px !important;
  --epr-picker-height: 400px !important;
  width: 291px !important;
  height: 400px !important;

  @include mediumScreen() {
    --epr-picker-width: 280px !important;
    --epr-picker-height: 350px !important;
    width: 280px !important;
    height: 350px !important;
  }

  @include smallScreen() {
    --epr-picker-width: 250px !important;
    --epr-picker-height: 300px !important;
    width: 250px !important;
    height: 300px !important;
  }
}

#demo-popup-popover {
  z-index: 12345678888 !important;
}

.chat-box-scrolldown-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(247, 247, 247);
}

.chat-box-scroll-down-btn {
  border: none;
  outline: none;
  border-radius: 50%;
  padding: 15px;
  position: absolute;
  bottom: 75px;
  background-color: rgba(80, 50, 250, 0.9411764706);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.messages-loading-circle {
  text-align: center;
}

.infinite-scroll-component {
  overflow: unset !important;
}

.messages-container {
  overflow: auto;
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column-reverse;
}

.messages-container::-webkit-scrollbar {
  width: 8px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.file-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #eef0f2;
  align-items: center;
  word-break: break-all;

  & .file-name-container {
    display: flex;
    gap: 10px;
  }

  & .image-outlined-icon {
    color: #6932fa;
  }

  & .file-name-text {
    font-family: "Poppins", sans-serif !important;
    font-weight: 500 !important;
  }
}

.messages-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.colored::-webkit-scrollbar-thumb {
  background-color: var(--dynamic-color) !important;
}

.messages-container::-webkit-scrollbar-thumb {
  background-color: rgba(80, 50, 250, 0.9411764706);
  outline: none;
}

.loading-infinite-scroll-container {
  text-align: center;
}

.no-events {
  pointer-events: none;
}

.colored {
  >path {
    fill: var(--dynamic-color);
  }
}

.header {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body-title {
  height: 72vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 75px;
}

.chatbox-header-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .close-btn-container {
    background: #fff;
    padding: 5px;

    .close-btn-icon {
      font-size: 16px;
    }
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.record-fade-btn {
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
  animation: flickerAnimation 2s infinite;
  padding-top: 6px;
}
.audio-container{
  width:70%;
  display:flex;
  align-items: center;
}