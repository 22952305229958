@use "../../assets/styles/abstracts/" as *;

.taki-chat-modal-backdrop {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  cursor: pointer;
  animation: fadeIn 0.6s;
  z-index: 9;

  .client-chatbox {
    pointer-events: none;
  }
}

.rating-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 20px;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  animation: fadeIn 0.6s;

  .rating-modal-header {
    @include flex(null, center, center);

    .rating-modal-circle {
      @include flex(null, center, center);

      width: 69px;
      height: 69px;
      border: 1px solid #ebebeb;
      border-radius: 50%;
      margin-bottom: 18px;

      .rating-modal-circle-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--dynamic-color) !important;
        width: 61px;
        height: 61px;
        border-radius: 50%;

        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .rating-modal-body {
    display: flex;
    flex-direction: column;
    width: 100%;

    .rating-modal-body-title {
      font-weight: 550;
      font-size: 15px;
      text-align: center;
      text-transform: capitalize;
      color: #000000;
      margin-bottom: 10px;
    }

    .rating-modal-body-emoji {
      display: flex;
      gap: 15px;
      align-self: center;

      .rating-modal-body-emoji-item {
        width: 35px;
        height: 35px;
        filter: grayscale(1);
        transition: 0.1s all ease-in;

        &:hover {
          transform: scale(1.1);
        }

        img {
          width: 100%;
          height: 100%;
        }

        cursor: pointer;
      }
    }

    .rating-modal-body-description {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .description {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-transform: capitalize;
        margin-bottom: 10px;
        margin-top: 20px;
      }

      textarea {
        padding: 10px;
        min-height: 100px;
        resize: none;
        border: 1px solid #ebebeb;

        border-radius: 5px;

        &:focus {
          outline: 2px solid var(--dynamic-color) !important;
        }
      }
    }

    .rating-modal-footer-button {
      @include flex(null, center, center);
      min-height: 42px;
      color: var(--dynamic-color) !important;
      border: 1px solid var(--dynamic-color) !important;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: var(--dynamic-color) !important;
        color: white !important;
      }
    }
  }
}

.colored {
  filter: unset !important;
}