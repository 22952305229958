// mixin
@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin extraSmallScreen() {
  @media only screen and (min-width: 0px) and (max-width: 560px) {
    @content;
  }
}

@mixin smallScreen() {
  @media only screen and (min-width: 561px) and (max-width: 620px) {
    @content;
  }
}
@mixin mediumScreen() {
  @media only screen and (min-width: 620px) and (max-width: 768px) {
    @content;
  }
}
@mixin largeScreen() {
  @media only screen and (min-width: 1200px) and (max-width: 1700px) {
    @content;
  }
}
